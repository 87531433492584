/* You can add global styles to this file, and also import other style files */
.ace-switch-sino:checked::before {
    content: "SI";
}

.ace-switch-sino::before {
    content: "NO";
}

.enable-columns-bar{
    z-index: 1500 !important;
}

.ace-switch-servicio:checked::before {
    content: "SERVICIO";
}

.ace-switch-servicio::before {
    content: "ITEM";
}

.ace-switch-servicio {
    width:130px !important;
}

.ace-switch-servicio::after{
    left: 3.25rem !important;
}

.ace-switch-item:checked::before {
    content: "COMBO";
}

.ace-switch-item::before {
    content: "PRODUCTO";
}

.ace-switch-item {
    width:130px !important;
}

.ace-switch-item::after{
    left: 1.75rem !important;
}

a.disabled { 
    pointer-events: none; 
}

.ace-switch-desc:checked::before {
    content: "%";
}

.ace-switch-desc::before {
    content: "Bs";
}